<template>
  <v-row no-gutters align="center" justify="center" class="fill-height py-16" :class="{ 'mt-8': $vuetify.breakpoint.mdAndDown }">
    <v-col cols="10" lg="8" xl="6">
      <v-form ref="form" @submit.prevent="confirmarCodigo" lazy-validation>
        <v-row no-gutters align="center" justify="center">
          <v-col cols="12" class="head-title mb-2 text-center"> Confirmação de Código </v-col>
          <v-col cols="12" class="subtitle mb-10 text-center" style="color: grey">
            Digite o código de confirmação enviado para o seu email.
          </v-col>
          <v-col cols="12">
            <v-text-field
              label="Código de Confirmação"
              outlined
              v-model="tokenEmail"
              maxlength="10"
              :rules="[() => !!tokenEmail || 'Campo obrigatório']"
            ></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-row>
              <v-spacer></v-spacer>
              <v-btn
                color="primary"
                rounded
                x-large
                class="elevation-12"
                @click="confirmarCodigo"
                :loading="loading"
              >
                <span class="capitalize">Confirmar</span>
                <v-icon size="20" right>mdi-check-circle-outline</v-icon>
              </v-btn>
              <v-btn
                color="secondary"
                rounded
                x-large
                class="elevation-12 ml-2"
                @click="reenviarEmail"
                :loading="loading"
              >
                <span class="capitalize">Reenviar Email</span>
                <v-icon size="20" right>mdi-email-outline</v-icon>
              </v-btn>
            </v-row>
          </v-col>
        </v-row>
      </v-form>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from 'vuex'
import autenticacaoService from '@/services/autenticacao'

export default {
  data() {
    return {
      tokenEmail: '',
      loading: false,
    }
  },
  computed: {
    ...mapGetters('agendamento', ['getEmail']),
  },
  methods: {
    async confirmarCodigo() {
      if (!this.tokenEmail) {
        return
      }
      this.loading = true
      try {
        await autenticacaoService.confirmarToken(this.tokenEmail)
        this.showSuccessOnSnackbar('Código confirmado com sucesso!')
        this.$router.push({ name: 'agendamento-perfil' })
      } catch (error) {
        console.error('Erro ao confirmar código:', error)
        this.showErrorOnSnackbar('Código inválido')
      } finally {
        this.loading = false
      }
    },
    async reenviarEmail() {
      this.loading = true
      try {
        await autenticacaoService.enviarTokenEmail(this.getEmail)
        this.showSuccessOnSnackbar('Email reenviado com sucesso!')
      } catch (error) {
        console.error('Erro ao reenviar email:', error)
        this.showErrorOnSnackbar('Erro ao reenviar email')
      } finally {
        this.loading = false
      }
    },
  },
}
</script>
