import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store/index'
import autenticacaoService from '@/services/autenticacao'
import Agendamento from '@/views/Agendamento.vue'
import EscolherAgendamento from '@/components/Agendamento/EscolherAgendamento.vue'
import AgendamentoClinicas from '@/components/Agendamento/AgendamentoClinicas.vue'
import AgendamentoEmpresa from '@/components/Agendamento/AgendamentoEmpresa.vue'
import AgendamentoSegmentos from '@/components/Agendamento/AgendamentoSegmentos.vue'
import AgendamentoColaborador from '@/components/Agendamento/AgendamentoColaborador/AgendamentoColaborador.vue'
import ColaboradorDados from '@/components/Agendamento/AgendamentoColaborador/ColaboradorDados.vue'
import ColaboradorResumo from '@/components/Agendamento/AgendamentoColaborador/ColaboradorResumo.vue'
import AgendamentoPagamento from '@/components/Agendamento/AgendamentoPagamento/AgendamentoPagamento.vue'
import AgendamentoPerfil from '@/components/Agendamento/AgendamentoPerfil.vue'
import AgendamentoRedefinirSenha from '@/components/Agendamento/AgendamentoRedefinirSenha.vue'
import AgendamentoEnviarTokenEmailForm from '@/components/Agendamento/AgendamentoEnviarTokenEmailForm.vue'
import AgendamentoSucesso from '@/components/Agendamento/AgendamentoSucesso.vue'
import MeiosPagamento from '@/components/Agendamento/AgendamentoPagamento/MeiosPagamento.vue'
import CreditCardForm from '@/components/Agendamento/AgendamentoPagamento/CreditCardForm.vue'
import PixQrCode from '@/components/Agendamento/AgendamentoPagamento/PixQrCode.vue'
import ConfirmacaoEmail from '@/components/Agendamento/ConfirmacaoEmail.vue'
import FormularioPerfil from '@/components/Agendamento/AgendamentoPerfil/FormularioPerfil.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: Agendamento,
    children: [
      {
        path: '',
        name: 'escolher-agendamento',
        component: EscolherAgendamento,
        meta: { step: 1 },
      },
      {
        path: 'clinicas',
        name: 'agendamento-clinicas',
        component: AgendamentoClinicas,
        meta: { step: 2 },
        props: true,
      },
      {
        path: 'cnpj',
        name: 'empresa-cnpj',
        component: AgendamentoEmpresa,
        meta: { step: 3 },
      },
      {
        path: 'segmento',
        name: 'empresa-segmento',
        component: AgendamentoSegmentos,
        meta: { step: 4 },
      },
      {
        path: 'perfil',
        component: AgendamentoPerfil,
        children: [
          {
            path: '',
            name: 'agendamento-perfil',
            component: FormularioPerfil,
            meta: { step: 5 },
          },
          {
            path: 'confirmacao-email',
            name: 'confirmacao-email',
            component: ConfirmacaoEmail,
            meta: { step: 5 },
          },
        ],
      },
      {
        path: 'colaborador',
        component: AgendamentoColaborador,
        meta: { requiresAuth: true },
        beforeEnter: (to, from, next) => {
          if (autenticacaoService.isAuthenticated()) {
          next()
        } else {
          next({ name: 'confirmacao-email' })
        }
      },
        children: [
          {
            path: '',
            name: 'colaborador',
            component: ColaboradorResumo,
            meta: { step: 6 },
          },
          {
            path: 'dados/:cpf?',
            name: 'colaborador-dados',
            component: ColaboradorDados,
            meta: { step: 6 },
          },
        ],
      },
      {
        path: 'checkout',
        component: AgendamentoPagamento,
        meta: { requiresAuth: true },
        children: [
          {
            path: '',
            name: 'agendamento-pagamento',
            component: MeiosPagamento,
            meta: { step: 7 },
          },
          {
            path: 'cartao-de-credito',
            name: 'agendamento-pagamento-cartao-credito',
            component: CreditCardForm,
            meta: { step: 7 },
          },
          {
            path: 'pix/:paymentId',
            name: 'agendamento-pagamento-pix',
            component: PixQrCode,
            meta: { step: 7, disableCartFloatingButton: true },
            props: true,
          },
        ],
      },
      {
        path: 'sucesso/:paymentId',
        name: 'agendamento-sucesso',
        component: AgendamentoSucesso,
        props: true,
        meta: { step: 8, requiresAuth: true, disableCartFloatingButton: true },
      },
    ],
  },
  {
    path: '/redefinir-senha',
    name: 'redefinir-senha',
    component: AgendamentoRedefinirSenha,
    meta: { disableCartFloatingButton: true },
  },
  {
    path: '/recuperar-senha',
    name: 'recuperarSenha',
    component: AgendamentoEnviarTokenEmailForm,
    meta: { disableCartFloatingButton: true },
  },
]

function currentStep() {
  return store.getters['agendamento/getActualStep']
}

const router = new VueRouter({
  mode: 'history',
  routes,
})

router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some(m => m.meta.requiresAuth)
  const isAuthenticated = autenticacaoService.isAuthenticated()

  if (!isAuthenticated && requiresAuth) {
    next({ name: 'confirmacao-email' })
  } else if (to.meta.step > currentStep()) {
    next({ name: 'escolher-agendamento' })
  } else {
    next()
  }
})

export default router
