<template>
  <v-row no-gutters align="center" justify="center" class="fill-height py-15" :class="{ 'mt-7': $vuetify.breakpoint.mdAndDown }">
    <v-col cols="12">
      <v-row no-gutters justify="center">
        <v-col cols="12">
          <v-row no-gutters align="center" justify="center">
            <v-col cols="12" class="head-title mb-2 text-center"> Escolha a forma de pagamento </v-col>
            <v-col cols="12" class="subtitle text-center"> Escolha a forma de pagamento para o seu atendimento </v-col>
          </v-row>
        </v-col>
      </v-row>
      <div class="d-flex justify-center align-center my-5">
        <div
          v-for="paymentType in paymentTypes"
          :key="paymentType.slug"
          class="d-flex flex-column align-center text-center justify-center pagamento-item"
          :class="{
            'pagamento-item-disabled': paymentType.disabled,
            highlighted: selectedPayment === paymentType.slug,
          }"
          @click="selectPayment(paymentType)"
        >
          <v-icon size="80" :color="paymentType.disabled ? '#c7c7c7' : '#005DFF'">{{ paymentType.icon }}</v-icon>
          {{ paymentType.title }}
          <v-chip v-if="paymentType.tag" class="success">{{ paymentType.tag }}</v-chip>
        </div>
      </div>
      <v-row no-gutters justify="center" class="mt-16 button-placeholder">
        <v-scroll-y-reverse-transition hide-on-leave>
          <v-btn
            v-show="selectedPayment === 'PIX'"
            :loading="loading"
            :disabled="loading"
            color="primary"
            rounded
            large
            class="elevation-12"
            @click="pixPayment()"
          >
            <span>Confirmar a compra</span>
            <v-icon right>mdi-cart-check</v-icon>
          </v-btn>
        </v-scroll-y-reverse-transition>

        <v-scroll-y-reverse-transition hide-on-leave>
          <v-btn
            v-show="selectedPayment === 'CREDIT_CARD'"
            color="primary"
            rounded
            large
            class="elevation-12"
            @click="creditCardStep()"
          >
            <span>Preencher os dados do cartão</span>
            <v-icon right>mdi-credit-card-plus</v-icon>
          </v-btn>
        </v-scroll-y-reverse-transition>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import agendamentoMixin from '@/mixins/agendamentoMixin'

export default {
  mixins: [agendamentoMixin],
  data() {
    return {
      loading: false,
      paymentTypes: [
        {
          title: 'Pagar com PIX',
          icon: 'mdi-qrcode',
          slug: 'PIX',
        },
        {
          title: 'Cartão de Crédito',
          icon: 'mdi-credit-card-outline',
          slug: 'CREDIT_CARD',
        },
      ],
    }
  },
  computed: {
    ...mapGetters('agendamento', ['getTipoPagamento']),

    selectedPayment: {
      get() {
        return this.getTipoPagamento
      },
      set(value) {
        this.setTipoPagamento(value)
      },
    },
  },
  methods: {
    ...mapActions('agendamento', ['finishAgendamentoExpress']),
    ...mapMutations('agendamento', { setTipoPagamento: 'SET_TIPO_PAGAMENTO' }),

    selectPayment(payment) {
      if (!payment.disabled) {
        this.selectedPayment = payment.slug
      }
    },
    async pixPayment() {
      this.loading = true
      try {
        const { data } = await this.finishAgendamentoExpress()
        this.actualStepWrapper = 7
        this.$router.push({ name: 'agendamento-pagamento-pix', params: { paymentId: data.paymentId } })
      } catch (error) {
        console.log(error)
        this.showErrorOnSnackbar(error.response.data.message)
      } finally {
        this.loading = false
      }
    },
    creditCardStep() {
      this.actualStepWrapper = 7
      this.$router.push({ name: 'agendamento-pagamento-cartao-credito' })
    },
  },
}
</script>

<style scoped>
.pagamento-item {
  background-color: #efefef !important;
  border-radius: 6px !important;
  padding: 20px;
  margin: 0px 20px;
  width: 150px;
  height: 180px;
  color: #005dff;
  font-size: 0.9rem;
}
.pagamento-item:hover {
  transform: scale(1.1);
  border: 1px solid #006bf2;
  cursor: pointer;
}
.pagamento-item-disabled {
  color: #c7c7c7 !important;
}
.pagamento-item-disabled:hover {
  cursor: not-allowed !important;
  transform: none !important;
  border: none;
}
.highlighted {
  transform: scale(1.1);
  border: 1px solid #006bf2;
}
.button-placeholder {
  min-height: 44px;
}
</style>
